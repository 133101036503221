<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-red-500 mb-4" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
    <h1 class="text-3xl font-semibold text-gray-800 mb-2">Maksu peruutettu</h1>
    <p class="text-gray-600 mb-6">
      Maksusi peruutettiin. Yritä uudelleen tai ota yhteyttä asiakaspalveluumme, jos tarvitset apua.
    </p>
    <router-link to="/hankeapuri" class="my-indigo-button">
      Palaa työpöydälle
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CancelPage",
  setup() {
    return {};
  },
};
</script>

<style scoped>
.my-indigo-button {
  background-color: #4f46e5;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.my-indigo-button:hover {
  background-color: #4338ca;
}
</style>