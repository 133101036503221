<template>
    <div class="min-h-screen bg-gray-100 text-gray-900">
        <header class="px-4 lg:px-6 py-4 bg-white shadow">
            <div class="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
                <div class="flex items-center flex-shrink-0 text-indigo-600 mr-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path
                            d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z">
                        </path>
                        <path
                            d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z">
                        </path>
                    </svg>
                    <span class="ml-2 text-2xl font-bold text-gray-800">Hankeapuri AI</span>
                </div>
                <div class="block lg:hidden">
                    <button @click="toggleMenu"
                        class="flex items-center px-3 py-2 border rounded text-indigo-600 border-indigo-600 hover:text-indigo-700 hover:border-indigo-700">
                        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                        </svg>
                    </button>
                </div>
                <nav :class="{ 'hidden': !showMenu, 'block': showMenu }"
                    class="w-full flex-grow lg:flex lg:items-center lg:w-auto">
                    <div class="text-sm lg:flex-grow">
                        <a href="/osta"
                            class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">
                            Osta lisää
                        </a>
                        <p class="block mt-4 lg:inline-block lg:mt-0 text-gray-700 mr-4">
                            Krediittejä: <span class="text-gray-900">{{ (userCredits / 100).toFixed(1) }}</span>
                        </p>
                        <a href="/hankkeet"
                            class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">
                            Omat hankkeet
                        </a>
                        <a href="/hankepohjat"
                            class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">
                            Hankepohjat
                        </a>
                        <span v-if="currentUser" class="block mt-4 lg:inline-block lg:mt-0 text-gray-700">
                            Käyttäjä: {{ currentUser.email }}
                        </span>
                    </div>
                    <div>
                        <a href="#" @click.prevent="handleLogout"
                            class="inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-700 mt-4 lg:mt-0">
                            Kirjaudu ulos
                        </a>
                    </div>
                </nav>
            </div>
        </header>
        <main>
            <div class="py-12">
                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6">
                        <div v-if="successMessage"
                            class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4 mb-4"
                            role="alert">
                            <strong class="font-bold">Onnistui!</strong>
                            <span class="ml-3 block sm:inline">{{ successMessage }}</span>
                        </div>

                        <div class="mb-4">
                            <label for="hankeSelect" class="block text-sm-label font-bold text-gray-700 mb-4">Valitse
                                Hankepohja</label>
                            <select id="hankeSelect" v-model="selectedHanke"
                                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                <option disabled value="">Valitse Yksi</option>
                                <option v-for="hanke in sortedHankes" :key="hanke.id" :value="hanke.id">{{ hanke.name }}
                                </option>
                                <option value="createNew">Luo hankepohja</option>
                            </select>
                        </div>
                        <div class="mt-4">
                            <label for="projectName" class="block text-sm-label font-bold text-gray-700 mb-2">Hankkeen
                                nimi
                            </label>
                            <input id="projectName" v-model="projectName" type="text" placeholder="Anna Hankkeelle nimi"
                                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                        </div>
                        <h1 class="text-sm font-bold leading-tight mt-5">Tietolähteet hakemuksen avuksi</h1>
                        <div class="mt-6">

                            <FileDropZone v-model:files="files" />
                            <ul class="file-list list-none p-0 m-0">
                                <li v-for="(file, index) in files" :key="index"
                                    class="file-item flex justify-between items-center p-4 border-b border-gray-300 hover:bg-gray-100 transition-colors duration-300">
                                    {{ file.name }}
                                    <span class="ml-4 text-gray-500">Krediittiä: {{ file.creditCount }}</span>
                                    <button @click="removeFile(index)"
                                        class="ml-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300">
                                        <font-awesome-icon icon="trash" />
                                    </button>
                                </li>
                            </ul>

                        </div>
                        <div class="mt-6">

                            <ul class="scraped-texts-list">
                                <li v-for="(scrapedText, index) in scrapedTexts" :key="index" class="scraped-text-item">
                                    <div
                                        class="flex justify-between items-center p-4 border-b border-gray-300 hover:bg-gray-100 transition-colors duration-300">
                                        <span class="text-sm">{{ scrapedText.url }}</span>

                                        <button @click="removeScrapedText(index)"
                                            class="ml-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300">Poista</button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="flex gap-4 p-4 border border-gray-300 shadow-sm rounded-md">
                            <input v-model="websiteUrl" type="text" placeholder="Lisää nettisivu URL / Y-Tunnus"
                                class="w-full md:w-auto p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500">
                            <button @click="scrapeText" class="my-indigo-button">
                                <span v-show="!isLoadingText">Lataa nettisivu / tiedot Y-tunnuksella (1234567-8)</span>
                                <span v-show="isLoadingText" class="spinner"></span>
                            </button>

                        </div>

                        <div class="mt-6">
                            <label for="inputText" class="block text-sm font-medium text-gray-700 mb-2">Kirjoita /
                                sanele
                                lisätietoja tai lisäohjeita</label>
                            <textarea id="inputText" v-model="inputText" rows="8" ref="inputTextArea"
                                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full block ext-base border border-gray-300 rounded-md mb-3"></textarea>
                            <button @click="toggleRecording" :class="['record-button', { 'is-recording': isRecording }]"
                                aria-label="Start or stop recording">
                                <font-awesome-icon v-show="!isRecording" :icon="['fas', 'microphone']"
                                    class="text-white" />
                                <font-awesome-icon v-show="isRecording" :icon="['fas', 'microphone']"
                                    class="text-white" />
                                <div v-show="isLoadingTextMic" class="spinner"></div>
                            </button>

                        </div>
                        <div class="mt-6">
                            <div v-show="false" class="flex items-center gap-6 my-4">
                                <label for="aiTemperature" class="block text-sm font-medium text-gray-700">Säädä luovan
                                    vapauden astetta:</label>
                                <input type="range" id="aiTemperature" min="0" max="1" step="0.01"
                                    v-model="aiTemperature" class="slider">
                                <span>{{ aiTemperature }}</span>
                            </div>
                            <div v-if="successMessageBottom"
                                class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-4 mb-4"
                                role="alert">
                                <strong class="font-bold">Onnistui!</strong>
                                <span class="ml-3 block sm:inline">{{ successMessageBottom }}</span>
                            </div>
                            <button @click="handleLaunchAI"
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none mr-4">
                                <span v-show="!loadingAI">Luo tekstit AI</span>
                                <span v-show="loadingAI">Odota hetki ☕....</span>
                            </button>

                            <button @click="saveProject" class="my-indigo-button">Tallenna omiin hankkeisiin</button>


                            <button @click="saveAsDocx" class="my-indigo-button">Tallenna DOCX</button>



                            <input type="file" id="jsonFile" ref="jsonInput" @change="loadFromJson"
                                style="display: none" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="critiqueResults && critiqueResults.length > 0" class="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-6">
                <div class="response-category mb-6">
                    <div class="card bg-white p-4 rounded-lg shadow-lg">
                        <div class="card-header bg-indigo-100 p-3 rounded-t-lg flex justify-between items-center mb-3">
                            <h2 class="text-xl font-bold text-indigo-600 card-header-title">Kritiikin tulokset</h2>
                        </div>
                        <div class="card-content text-gray-700" v-html="formattedCritiqueResults"></div>
                    </div>
                </div>
            </div>
            <AiResponseCard ref="aiResponseCardRef" :aiResponse="aiResponse" @ai-fixes="aiFixes"
                @update-ai-response="updateAiResponse" @submit-feedback="handleDefaultFeedback"
                @submit-feedback-subkey="fillWithAI" />
            <div v-if="showFeedbackModal"
                class="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
                <div class="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4">
                    <div class="flex justify-between items-center mb-4">
                        <h3 class="text-xl font-semibold text-gray-900">AI: Miten muokataan?</h3>
                        <button @click="closeFeedbackModal"
                            class="text-gray-500 hover:text-gray-700 focus:outline-none">
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="mb-4">
                        <div class="flex flex-wrap gap-2">
                            <button v-for="item in predefinedTexts" :key="item.label"
                                @click="applyPredefinedText(item.text)"
                                class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                {{ item.label }}
                            </button>
                        </div>
                    </div>
                    <div class="mb-4">
                        <textarea v-model="feedbackText" rows="4" placeholder="Kirjoita muokkausehdotuksesi"
                            class="w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"></textarea>
                    </div>
                    <div v-if="loadingEdits" class="flex justify-center items-center mb-4">
                        <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-500"></div>
                    </div>
                    <div class="flex justify-end space-x-2">
                        <button @click="fillWithAI(null)"
                            class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Tee muokkaus
                        </button>
                        <button v-if="false" @click="fillWithAIForAll"
                            class="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Tee muokkaus kaikkiin
                        </button>
                    </div>
                </div>
            </div>

        </main>
    </div>
</template>

<script>


import { database } from "@/firebase.js";
import { ref as firebaseRef, get, push, set, onValue, update } from "firebase/database";
import { marked } from 'marked';
import { transcribeAudio, launchAI, submitFeedback } from '@/services/apiService';
import { ref, onMounted, computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import AiResponseCard from './AiResponseCard.vue';
GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';
import FileDropZone from './FileDropZone.vue';
import { generateDocx } from '@/services/apiService';
import { saveAs } from 'file-saver';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import useAuthenticationCheck from '@/composables/useAuthenticationCheck';

export default {
    name: 'HankeApuri',
    components: {
        AiResponseCard,
        FileDropZone,

    },
    computed: {
        sortedHankes() {
            return this.hankes.slice().sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    emits: ['aiResponseUpdated'],
    setup() {
        const successMessageBottom = ref('');
        const store = useStore();
        const aiResponseCardRef = ref(null);
        const aiTemperature = ref(0.5);
        const files = computed(() => store.state.files.files);
        const driveFiles = ref([]);
        const selectedHanke = ref('');
        const hankes = ref([]);
        const inputText = ref('');
        const projectName = ref('');
        const aiResponse = computed(() => store.getters['content/getAiResponse']);
        const showFeedbackModal = ref(false);
        const feedbackText = ref('');
        const feedbackTarget = ref(null);
        const loadingAI = ref(false);
        const loadingEdits = ref(false);
        const isRecording = ref(false);
        let mediaRecorder = ref(null);
        const audioChunks = ref([]);
        const isLoadingText = ref(false);
        const isLoadingTextMic = ref(false);
        const showMenu = ref(false);
        const jsonInput = ref(null);
        const websiteUrl = ref('');
        const scrapedTexts = ref([]);
        const route = useRoute();
        const projectId = ref(route.params.projectId);
        const critiqueResults = ref('');
        const router = useRouter();
        const currentUser = computed(() => store.state.auth.user);
        const userCredits = ref(0);
        const tokensUsed = ref(0);
        const tokensRemaining = computed(() => userCredits.value);
        const monthlyTokenLimit = 1000;
        const successMessage = ref('');
        const { checkAuthentication } = useAuthenticationCheck();
        const inputTextArea = ref(null);


        watch(projectId, async (newVal) => {
            if (newVal) {
                // Fetch project data from Firebase using newVal as the project ID
                // Populate your component's state with the fetched data
                console.log(`Should fetch project data for ID: ${newVal}`);
            }
        });

        const resizeTextarea = () => {
            nextTick(() => {
                const textarea = inputTextArea.value;
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 'px';
            });
        };



        watch(inputText, () => {
            resizeTextarea();
        });

        watch(selectedHanke, () => {
            resizeTextarea();
        });

        watch(selectedHanke, (newVal) => {
            if (newVal === 'createNew') {
                router.push({ path: '/hankecreator' });
            }
        });

        const formattedCritiqueResults = computed(() => {
            return marked(critiqueResults.value);
        });

        const handleLogout = async () => {
            await store.dispatch('auth/logout');

        };

        const updateTokenUsage = (usage) => {
            tokensUsed.value += usage;
            tokensRemaining.value = monthlyTokenLimit - tokensUsed.value;
        };

        const toggleMenu = () => {
            showMenu.value = !showMenu.value;
        };

        const fetchTokenUsage = () => {
            const user = store.state.auth.user;

            if (user) {
                const tokenUsageRef = firebaseRef(database, `users/${user.uid}/tokenUsage`);
                onValue(tokenUsageRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        tokensUsed.value = data.used || 0;
                        tokensRemaining.value = monthlyTokenLimit - tokensUsed.value;
                    } else {
                        // If data is null, initialize the token usage to 0
                        tokensUsed.value = 0;
                        tokensRemaining.value = monthlyTokenLimit;
                    }
                });
            }
        };

        const saveTokenUsage = async (usage) => {
            const user = store.state.auth.user;
            if (user) {
                const userRef = firebaseRef(database, `users/${user.uid}`);
                const snapshot = await get(userRef);
                const userData = snapshot.val();
                const currentCredits = userData.credits || 0;
                const newCredits = currentCredits - usage;
                await update(userRef, {
                    credits: newCredits,
                    tokenUsage: {
                        used: tokensUsed.value + usage
                    }
                });
                userCredits.value = newCredits;
            }
        };

        const predefinedTexts = ref([
            { label: 'Pidempi', text: 'Tee tekstistä pidempi ja yksityiskohtaisempi' },
            { label: 'Lyhyempi', text: 'Tiivistä tekstiä hieman lyhyemmäksi' },

            // Add more predefined texts as needed
        ]);

        const applyPredefinedText = (text) => {
            feedbackText.value = text; // Abracadabra! The textarea is now filled.
        };


        const handleDefaultFeedback = ({ key, feedbackTextDefault }) => {
            console.log('handleDefaultFeedback called with:', key, feedbackTextDefault);
            fillWithAI(key, null, feedbackTextDefault);
        };


        const saveAsJson = () => {
            const dataStr = JSON.stringify(aiResponse.value, null, 2);
            const blob = new Blob([dataStr], { type: "application/json;charset=utf-8" });
            saveAs(blob, "hakemus.json");
        };

        watch(aiTemperature, (newValue) => {
            console.log('The temperature has changed to:', newValue);
        });

        const loadJsonClick = () => {
            console.log('The button was clicked, darling. Let’s see if the party starts.');
            jsonInput.value && jsonInput.value.click();
        };

        const handleFilePicked = async (fileId) => {
            // Here you would use Google Drive API to get the file content by fileId
            // For now, let's just log it to show we've got it
            console.log(`File picked with ID: ${fileId}`);

            // Once you have the file content, you would add it to the files array
            // files.value.push(retrievedFile);
        };

        const stringifyWithOrder = (obj) => {
            const keys = Object.keys(obj);
            const orderedObj = {};

            keys.forEach((key) => {
                orderedObj[key] = obj[key];
            });

            return JSON.stringify(orderedObj);
        };




        const saveProject = async () => {
            const user = store.state.auth.user;
            if (!user) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Tallennus epäonnistui, valitettavasti et ole kirjautuneenä enää sisään.',
                });
                router.push({ name: 'Login' });
                return;
            }

            // If projectName is not set, ask for it
            if (!projectName.value) {

                Swal.fire({
                    icon: 'warning',
                    title: 'Huomio',
                    text: 'Hankkeen nimi on pakollinen!',
                });
                return;
            }

            console.log(aiResponse.value);
            // Prepare the project data, now including scrapedTexts
            const projectData = {
                selectedHanke: selectedHanke.value,
                userInput: inputText.value,
                aiResponse: stringifyWithOrder(aiResponse.value),
                files: files.value,
                name: projectName.value,
                scrapedTexts: scrapedTexts.value,
            };

            const projectsRef = firebaseRef(database, `users/${user.uid}/projects`);

            // Fetch all projects to check if the project name already exists
            const projectsSnapshot = await get(projectsRef);
            let existingProjectKey = null;
            projectsSnapshot.forEach((childSnapshot) => {
                if (childSnapshot.val().name === projectName.value) {
                    existingProjectKey = childSnapshot.key;
                }
            });

            if (existingProjectKey) {
                console.log("Project exists, updating...");
                await set(firebaseRef(database, `users/${user.uid}/projects/${existingProjectKey}`), projectData);
                successMessageBottom.value = "Projekti päivitetty onnistuneesti";
            } else {
                console.log("Creating new project...");
                await set(push(projectsRef), projectData);
                successMessageBottom.value = "Projekti päivitetty onnistuneesti";
            }

            // Clear the success message after a certain duration (e.g., 3 seconds)
            setTimeout(() => {
                successMessageBottom.value = '';
            }, 3000);
        };



        const saveAsDocx = () => {
            generateDocx(aiResponse.value); // Pass the AI response data
        };

        const loadFromJson = async (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const result = e.target.result;
                    try {
                        const loadedData = JSON.parse(result);
                        aiResponse.value = loadedData; // Update the state with the loaded data
                    } catch (error) {
                        console.error("Could not parse the JSON file:", error);
                    }
                };
                reader.readAsText(file);
            }
        };

        const scrapeText = async () => {
            if (!websiteUrl.value) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Huomio',
                    text: 'Anna nettisivun URL ensin / Y-tunnus',
                });
                return;
            }

            isLoadingText.value = true;

            try {
                if (isValidYTunnus(websiteUrl.value)) {

                    const url = "https://tietopalvelu.ytj.fi/yritys/" + websiteUrl.value;
                    // If the input is a Y-tunnus, fetch company data from PRH
                    const response = await axios.post('https://us-central1-businessdemos.cloudfunctions.net/scrapeWebsiteText', {
                        url: url,
                    });

                    scrapedTexts.value.push({
                        url: websiteUrl.value,
                        text: response.data.text,
                        characterCount: response.data.text.length,
                    });

                } else {
                    // If the input is a URL, proceed with web scraping
                    const response = await axios.post('https://us-central1-businessdemos.cloudfunctions.net/scrapeWebsiteText', {
                        url: websiteUrl.value,
                    });

                    scrapedTexts.value.push({
                        url: websiteUrl.value,
                        text: response.data.text,
                        characterCount: response.data.text.length,
                    });
                }
            } catch (error) {
                console.error('Error scraping website or fetching company data:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Virhe',
                    text: 'Tapahtui virhe tietojen haussa.',
                });
            } finally {
                isLoadingText.value = false;
            }
        };

        // Helper function to validate Y-tunnus format
        const isValidYTunnus = (yTunnus) => {
            // Check the basic format "1234567-8"
            const regex = /^\d{7}-\d$/;
            if (!regex.test(yTunnus)) {
                return false;
            }

            // Extract parts
            const parts = yTunnus.split('-');
            const numberPart = parts[0];
            const checkDigit = parseInt(parts[1], 10);

            // Calculate the check digit
            const calculatedCheckDigit = calculateCheckDigit(numberPart);

            // Compare the calculated check digit with the provided one
            return checkDigit === calculatedCheckDigit;
        };

        const calculateCheckDigit = (numberPart) => {
            const multiplier = [7, 9, 10, 5, 8, 4, 2];
            let sum = 0;

            // Multiply each digit by its corresponding multiplier and sum them up
            for (let i = 0; i < numberPart.length; i++) {
                sum += parseInt(numberPart[i], 10) * multiplier[i];
            }

            // Calculate the modulo
            const mod = sum % 11;

            // Determine the check digit based on the modulo
            if (mod === 0) {
                return 0;
            } else if (mod === 1) {
                return false; // Invalid, as 1 is not used
            } else {
                return 11 - mod;
            }
        };

        const removeScrapedText = (index) => {
            scrapedTexts.value.splice(index, 1);
        };



        const sendAudioToCloud = async (base64Audio) => {
            try {
                isLoadingTextMic.value = true; // Set loading state to true

                const response = await transcribeAudio(base64Audio);
                console.log(response.data);

                if (response && response.data.text) {
                    inputText.value += response.data.text; // Update the inputText with the transcribed text
                }
            } catch (error) {
                console.error('Error transcribing audio:', error);
                // Show error message using SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Jotain meni mönkään, yritä uudestaan.',
                });
            } finally {
                isLoadingTextMic.value = false; // Set loading state back to false
            }
        };

        const fillWithAIForAll = async () => {
            for (const feedbackKey of Object.keys(aiResponse.value)) {
                console.log('Submitting feedback for:', feedbackKey);
                await fillWithAI(feedbackKey);
            }
            closeFeedbackModal();
        };

        const handleCritique = async () => {
            loadingAI.value = true; // Let's pretend we're busy... because we are
            const formData = new FormData();

            // We're assuming selectedHanke has all the juicy details we need
            const selectedHankeDetails = hankes.value.find(hanke => hanke.id === selectedHanke.value);
            const hankeJSONAsObject = selectedHankeDetails.HankeJSON.reduce((obj, item) => {
                obj[item.key] = item.value;
                return obj;
            }, {});

            // Make sure we actually have a selected Hanke
            if (!selectedHankeDetails) {
                console.error("No Hanke selected, or it's missing. How can you launch AI without a target? Select a Hanke and try again, please.");
                loadingAI.value = false;
                return;
            }

            formData.append('Hankename', selectedHankeDetails.Hankename);
            formData.append('HankeJSON', JSON.stringify(hankeJSONAsObject));
            formData.append('Hankedetails', selectedHankeDetails.Hankedetails);
            formData.append('temperature', aiTemperature.value); // Add the temperature to the FormData
            // Include the input text in the formData
            formData.append('text', inputText.value);
            formData.append('aiResponse', JSON.stringify(aiResponse.value));

            if (scrapedTexts.value.length > 0) {
                formData.append('scrapedTexts', JSON.stringify(scrapedTexts.value.map(text => ({ url: text.url, content: text.text }))));
            }

            try {
                for (const fileObject of files.value) {
                    try {

                        // Directly use the pre-loaded text content
                        const textContent = fileObject.text;
                        formData.append(fileObject.name + '_text', textContent);
                    } catch (error) {
                        console.error('Error appending file text content:', error);
                    }
                }
            }
            catch {
                console.log("no files");
            }

            // Now send the formData to the server
            try {
                const response = await axios.post('https://jhapps-flutterapps.azurewebsites.net/api/hankeapuriAPI', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                // Assuming the response contains a markdown-formatted critique
                //critiqueResults.value = marked(response.data);
                let markdownContent = response.data; // This is your raw Markdown from the response

                // First, convert the Markdown content to HTML
                // First, convert the Markdown content to HTML
                let htmlContent = marked(markdownContent);

                let dummyDiv = document.createElement('div');
                dummyDiv.innerHTML = htmlContent;

                // Add Tailwind classes to h3 elements
                dummyDiv.querySelectorAll('h3').forEach(h3 => {
                    h3.classList.add('text-lg', 'font-semibold', 'text-gray-800', 'mb-4', 'mt-6'); // Added margin-bottom and margin-top
                });

                // Add Tailwind classes to p elements
                dummyDiv.querySelectorAll('p').forEach(p => {
                    p.classList.add('text-base', 'text-gray-600', 'mb-4'); // Added margin-bottom for spacing between paragraphs
                });

                // Let's also style lists (ul, ol) and list items (li) for completeness
                dummyDiv.querySelectorAll('ul, ol').forEach(list => {
                    list.classList.add('list-disc', 'list-inside', 'mb-4'); // Disc list style inside the element with margin-bottom
                });

                dummyDiv.querySelectorAll('li').forEach(listItem => {
                    listItem.classList.add('mb-2'); // Margin-bottom for each list item
                });

                // If you have blockquotes in your Markdown, let's style them too
                dummyDiv.querySelectorAll('blockquote').forEach(blockquote => {
                    blockquote.classList.add('pl-4', 'border-l-4', 'border-gray-400', 'italic', 'text-gray-600', 'mb-4'); // Styling for blockquotes
                });

                // Update your Vue data property with the manipulated HTML
                critiqueResults.value = dummyDiv.innerHTML;


            } catch (error) {
                console.error('Error posting to critique endpoint:', error);
                critiqueResults.value = 'Virhe, yritä uudestaan' + error;
            } finally {
                loadingAI.value = false; // We're done here, folks!
            }
        };

        const handleLaunchAI = async () => {
            loadingAI.value = true;
            // Remove old data
            aiResponse.value = null;
            store.dispatch('content/updateAiResponse', null);

            // Find the selected Hanke details
            const selectedHankeDetails = hankes.value.find(hanke => hanke.id === selectedHanke.value);

            // Ensure a Hanke is selected
            if (!selectedHankeDetails) {
                console.error("No Hanke selected.");
                loadingAI.value = false;
                return;
            }

            // Convert Hanke details to JSON object
            console.log('Selected Hanke Details:', selectedHankeDetails);
            const jsonobject = convertFirebaseToJson(selectedHankeDetails);

            // Prepare scraped texts
            const formattedScrapedTexts = scrapedTexts.value.map(text => ({
                url: text.url,
                content: text.text
            }));

            // Prepare files (if any) by converting them to base64 strings
            // Assuming 'fileObject.text' contains text data. If files are binary, use a different approach.
            const formattedFiles = await Promise.all(files.value.map(async (fileObject) => ({
                name: fileObject.name,
                text: fileObject.text // If file.text is already a string. Otherwise, convert to base64 if binary.
            })));


            // Construct the JSON payload
            const payload = {
                Hankename: projectName.value,
                HankeJSON: jsonobject,
                Hankedetails: selectedHankeDetails.details,
                temperature: aiTemperature.value, // Add the temperature
                text: inputText.value, // Include the input text
                scrapedTexts: formattedScrapedTexts,
                files: formattedFiles // Include formatted files
            };

            // Send the JSON payload to the server
            try {
                const response = await launchAI(payload);
                const responseJson = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

                const tokenUsage = responseJson.tokenUsage; // Assuming token usage is returned
                updateTokenUsage(tokenUsage);
                saveTokenUsage(tokenUsage);

                let aiContent = responseJson.content;
                // If 'content' is a JSON string, parse it. Adjust based on actual response structure.
                let aiResponseData = typeof aiContent === 'string' ? JSON.parse(aiContent) : aiContent;
                console.log(aiResponseData);

                store.dispatch('content/updateAiResponse', aiResponseData);
            } catch (error) {
                console.error('Error posting to AI backend:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Virhe',
                    text: 'Tapahtui virhe AI:n kanssa kommunikoinnissa.',
                });
            } finally {
                loadingAI.value = false;
            }
        };

        const handledriveFilesPicked = (pickedFiles) => {
            console.log('handledriveFilesPicked called with:', pickedFiles);
            driveFiles.value = [...driveFiles.value, ...pickedFiles];
        };


        /*
        const fillWithAI = async (givenKey = null, givenSubKey = null, feedbackTextGiven = null) => {
            console.log('fillWithAI called with:', givenKey, givenSubKey, feedbackTextGiven);

            if (tokensRemaining.value <= 0) {

                Swal.fire({
                    icon: 'warning',
                    title: 'Varoitus',
                    text: 'Tarvitset lisää krediittejä',
                });
                return;

            }

            loadingEdits.value = true;
            const formData = new FormData();


            // Include the feedback text
            if (feedbackTextGiven === null) {
                formData.append('feedbackText', feedbackText.value);
            } else {
                formData.append('feedbackText', feedbackTextGiven);
            }

            // Include the entire aiResponse as context
            formData.append('context', JSON.stringify(aiResponse.value));

            const selectedHankeDetails = hankes.value.find(hanke => hanke.id === selectedHanke.value);

            let jsonobject = convertFirebaseToJson(selectedHankeDetails);


            // Make sure we actually have a selected Hanke
            if (!selectedHankeDetails) {
                console.error("No Hanke selected, or it's missing. How can you launch AI without a target? Select a Hanke and try again, please.");
                loadingAI.value = false;
                return;
            }

            formData.append('Hankename', selectedHankeDetails.Hankename);
            formData.append('HankeJSON', JSON.stringify(jsonobject));
            formData.append('Hankedetails', selectedHankeDetails.Hankedetails);
            formData.append('temperature', aiTemperature.value); // Add the temperature to the FormData

            // Include the input text in the formData
            console.log("inputText", inputText.value);
            formData.append('originalText', inputText.value);

            if (givenKey !== null) {
                let targetTextContent;
                if (givenSubKey !== null) {
                    // If a subkey is provided, use it as the target
                    targetTextContent = aiResponse.value[givenKey][givenSubKey];
                    formData.append('subkey', true);
                } else {
                    // If no subkey is provided, use the main key as the target
                    targetTextContent = aiResponse.value[givenKey];
                }

                formData.append('target_text', JSON.stringify(targetTextContent));
            } else {
                const targetTextContent = aiResponse.value[feedbackTarget.value];
                formData.append('target_text', JSON.stringify(targetTextContent));
            }

            try {
                for (const fileObject of files.value) {
                    try {
                        // Directly use the pre-loaded text content
                        const textContent = fileObject.text;
                        formData.append(fileObject.name + '_text', textContent);
                    } catch (error) {
                        console.error('Error appending file text content:', error);
                    }
                }
            } catch {
                console.log("no files");
            }

            // Append the Google Drive files content
            for (const file of driveFiles.value) {
                formData.append(file.name + '_text', file.content);
            }

            // Append the website scraps
            if (scrapedTexts.value.length > 0) {
                formData.append('scrapedTexts', JSON.stringify(scrapedTexts.value.map(text => ({ url: text.url, content: text.text }))));
            }

            // Now, let's send the ensemble to the server
            try {
                const response = await submitFeedback(formData);
                // Handle the response with the grace of a prima ballerina
                const responseJson = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                const tokenUsage = responseJson.tokenUsage; // Assuming the token usage is returned in the response
                updateTokenUsage(tokenUsage);
                saveTokenUsage(tokenUsage);
                console.log('Response JSON:', responseJson);
                let jsonobject = JSON.parse(responseJson.content);

                if (givenKey) {
                    if (givenSubKey) {
                        // If a subkey is provided, handle feedback submission for the specific subkey
                        handleFeedbackSubmission(jsonobject, givenKey, givenSubKey);
                    } else {
                        // If no subkey is provided, handle feedback submission for the main key
                        handleFeedbackSubmission(jsonobject, givenKey);
                    }
                } else {
                    handleFeedbackSubmission(jsonobject, feedbackTarget.value);
                }
            } catch (error) {
                console.error('Error submitting feedback:', error);
            } finally {
                loadingEdits.value = false;
                aiResponseCardRef.value.resetLoadingState(); // Close the feedback modal or reset any other state as necessary

                // If targetKey is set, then we are submitting feedback for all, so don't close the modal
                if (!givenKey) {
                    closeFeedbackModal();
                    feedbackText.value = ''; // Reset the feedback text
                }
            }
        };
        */

        const fillWithAI = async (givenKey = null, givenSubKey = null, feedbackTextGiven = null) => {
            console.log('fillWithAI called with:', givenKey, givenSubKey, feedbackTextGiven);

            if (tokensRemaining.value <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Varoitus',
                    text: 'Tarvitset lisää krediittejä',
                });
                return;
            }

            loadingEdits.value = true;

            // Find the selected Hanke first
            const selectedHankeDetails = hankes.value.find(hanke => hanke.id === selectedHanke.value);


            // Check if selectedHankeDetails exists
            if (!selectedHankeDetails) {
                console.error("No Hanke selected, or it's missing. How can you launch AI without a target? Select a Hanke and try again, please.");
                Swal.fire({
                    icon: 'error',
                    title: 'Virhe',
                    text: 'Valittua hanketta ei löydy. Tarkista valinta tai yritä uudelleen.',
                });
                loadingEdits.value = false;
                return;
            }

            // Convert Hanke details to JSON object
            let jsonobject = convertFirebaseToJson(selectedHankeDetails);
            console.log('Converted JSON Object:', jsonobject);

            // Construct the payload as a plain JavaScript object
            const payload = {
                feedbackText: feedbackTextGiven || feedbackText.value,
                context: aiResponse.value,
                Hankename: projectName.value,
                HankeJSON: JSON.stringify(jsonobject),
                Hankedetails: selectedHankeDetails.details,
                temperature: aiTemperature.value,
                originalText: inputText.value,
            };

            // Handle target_text and subkey
            if (givenKey !== null) {
                let targetTextContent;
                if (givenSubKey !== null) {
                    // Ensure that givenKey exists and has the givenSubKey
                    if (
                        aiResponse.value[givenKey] &&
                        aiResponse.value[givenKey][givenSubKey]
                    ) {
                        targetTextContent = aiResponse.value[givenKey][givenSubKey];
                        payload.subkey = "true";
                    } else {
                        console.error(`Given key (${givenKey}) or subkey (${givenSubKey}) does not exist in aiResponse.`);
                        Swal.fire({
                            icon: 'error',
                            title: 'Virhe',
                            text: 'Valittu avain tai aliavain ei ole käytössä. Tarkista valinta tai yritä uudelleen.',
                        });
                        loadingEdits.value = false;
                        return;
                    }
                } else {
                    // Ensure that givenKey exists
                    if (aiResponse.value[givenKey]) {
                        targetTextContent = aiResponse.value[givenKey];
                        payload.subkey = "false";
                    } else {
                        console.error(`Given key (${givenKey}) does not exist in aiResponse.`);
                        Swal.fire({
                            icon: 'error',
                            title: 'Virhe',
                            text: 'Valittu avain ei ole käytössä. Tarkista valinta tai yritä uudelleen.',
                        });
                        loadingEdits.value = false;
                        return;
                    }
                }
                payload.target_text = JSON.stringify(targetTextContent);
            } else {
                // Ensure that feedbackTarget.value exists
                if (aiResponse.value[feedbackTarget.value]) {
                    const targetTextContent = aiResponse.value[feedbackTarget.value];
                    payload.target_text = JSON.stringify(targetTextContent);
                } else {
                    console.error(`Feedback target (${feedbackTarget.value}) does not exist in aiResponse.`);
                    Swal.fire({
                        icon: 'error',
                        title: 'Virhe',
                        text: 'Valittu palautteen kohde ei ole käytössä. Tarkista valinta tai yritä uudelleen.',
                    });
                    loadingEdits.value = false;
                    return;
                }
            }

            // Append file texts
            files.value.forEach(fileObject => {
                try {
                    // Directly use the pre-loaded text content
                    const textContent = fileObject.text;
                    payload[`${fileObject.name}_text`] = textContent;
                } catch (error) {
                    console.error('Error appending file text content:', error);
                }
            });

            // Append Google Drive files content
            driveFiles.value.forEach(file => {
                payload[`${file.name}_text`] = file.content;
            });

            // Append scraped texts as a JSON string
            if (scrapedTexts.value.length > 0) {
                payload.scrapedTexts = JSON.stringify(scrapedTexts.value.map(text => ({
                    url: text.url,
                    content: text.text
                })));
            }

            // Now, let's send the JSON payload to the server
            try {
                const response = await submitFeedback(payload);
                // Handle the response gracefully
                const responseJson = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
                const tokenUsage = responseJson.tokenUsage; // Assuming the token usage is returned in the response
                updateTokenUsage(tokenUsage);
                saveTokenUsage(tokenUsage);
                console.log('Response JSON:', responseJson);
                //check if content is a string
                let updatedAiResponse;
                if (typeof responseJson.content === 'string') {
                    updatedAiResponse = JSON.parse(responseJson.content);
                } else {
                    updatedAiResponse = responseJson.content;
                }

                if (givenKey) {
                    if (givenSubKey) {
                        // If a subkey is provided, handle feedback submission for the specific subkey
                        handleFeedbackSubmission(updatedAiResponse, givenKey, givenSubKey);
                    } else {
                        // If no subkey is provided, handle feedback submission for the main key
                        handleFeedbackSubmission(updatedAiResponse, givenKey);
                    }
                } else {
                    handleFeedbackSubmission(updatedAiResponse, feedbackTarget.value);
                }
            } catch (error) {
                console.error('Error submitting feedback:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Virhe',
                    text: 'Palautteen lähettäminen epäonnistui. Yritä uudelleen.',
                });
            } finally {
                loadingEdits.value = false;
                aiResponseCardRef.value.resetLoadingState(); // Reset loading state

                // If targetKey is not set, then we are submitting feedback for a specific key, so close the modal
                if (!givenKey) {
                    closeFeedbackModal();
                    feedbackText.value = ''; // Reset the feedback text
                }
            }
        };

        // This function is the maestro of the recording orchestra
        const toggleRecording = async () => {
            if (!isRecording.value) {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                mediaRecorder.value = new MediaRecorder(stream, { mimeType: 'audio/webm' });
                audioChunks.value = [];

                mediaRecorder.value.ondataavailable = (event) => {
                    audioChunks.value.push(event.data);
                };

                mediaRecorder.value.onstop = async () => {
                    const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm' });
                    const reader = new FileReader();

                    reader.onloadend = () => {

                        const base64AudioMessage = reader.result.toString().split(',')[1];

                        sendAudioToCloud(base64AudioMessage);
                    };

                    reader.readAsDataURL(audioBlob);
                };

                mediaRecorder.value.start();
                isRecording.value = true;
            } else {
                mediaRecorder.value.stop();
                isRecording.value = false;
                isLoadingTextMic.value = true; // Set loading state to true when stopping the recording

            }
        };

        const generatePDF = () => {
            const doc = new jsPDF();
            const aiResponseData = aiResponse.value || {};
            let yPos = 10;

            Object.values(aiResponseData).forEach((value) => {
                let text = '';
                if (typeof value === 'object') {
                    text = JSON.stringify(value, null, 2)
                        .replace(/[{}[\]"]/g, '')
                        .replace(/,/g, '');
                } else {
                    text = value.toString();
                }

                const lines = doc.splitTextToSize(text, 190);

                lines.forEach((line) => {
                    doc.text(line, 10, yPos);
                    yPos += 10;

                    if (yPos > 280) {
                        doc.addPage();
                        yPos = 10;
                    }
                });
            });

            doc.save('ai-response-polished.pdf');
        };

        const aiFixes = (key) => {
            feedbackTarget.value = key;
            console.log('aiFixes called with:', key);
            showFeedbackModal.value = true;
        };

        const closeFeedbackModal = () => {
            showFeedbackModal.value = false;
            feedbackText.value = '';
        };

        const handleFeedbackSubmission = (feedbackData, target, subkey = null) => {

            console.log("feedbackData", feedbackData);
            console.log("feedbackData", feedbackData.teksti);
            console.log("target", target);
            console.log("subkey", subkey);

            try {
                if (aiResponse.value[target]) {
                    if (subkey !== null) {
                        console.log(feedbackData.text);
                        store.dispatch('content/updateEditableContent', feedbackData.text);
                    } else {
                        // If no subkey is provided, apply changes to the main key
                        for (const [key, value] of Object.entries(feedbackData)) {
                            aiResponse.value[target][key] = value;
                        }
                        // Dispatch the action to update the aiResponse in the store
                        store.dispatch('content/updateAiResponse', aiResponse.value);
                    }
                }
            } catch (error) {
                console.error('Error updating feedback:', error);
            }
            finally {
                // Dispatch the action to set isProcessing.submitEdit to false
                store.dispatch('content/updateIsProcessing', { key: 'submitEdit', value: false });
                store.dispatch('content/updateLoadingState', { key: target, value: false });
            }

        };

        const getChangedTexts = () => {
            return {};
        };

        const removeFile = (index) => {
            store.dispatch('files/removeFile', index);
        };

        const parsePdf = async (file) => {
            try {
                const pdf = await getDocument(URL.createObjectURL(file)).promise;
                const textContent = await extractTextFromPdf(pdf);
                console.log(textContent);
                return textContent;
            } catch (error) {
                console.error('Error parsing PDF:', error);
            }
        };



        const convertFirebaseToJson = (selectedHankeDetails) => {
            console.log('firebase data', selectedHankeDetails);
            const convertedJson = {};
            console.log('Selected Hanke Details:', selectedHankeDetails.sections);
            if (selectedHankeDetails.sections) {
                const sortedSections = Object.values(selectedHankeDetails.sections)
                    .sort((a, b) => a.order - b.order);

                sortedSections.forEach((section) => {
                    const title = section.text;
                    convertedJson[title] = {};
                    if (section.subSections) {
                        const sortedSubSections = Object.values(section.subSections)
                            .sort((a, b) => a.order - b.order);

                        sortedSubSections.forEach((subSection) => {
                            const subtitle = subSection.text;
                            const description = subSection.description;
                            convertedJson[title][subtitle] = description;
                        });
                    }
                });
            }
            console.log('Converted JSON:', convertedJson);
            return convertedJson;
        };

        const extractTextFromPdf = async (pdf) => {
            let textContent = '';

            for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
                const page = await pdf.getPage(pageNum);
                const text = await page.getTextContent();
                textContent += text.items.map((item) => item.str).join(' ');
            }

            return textContent;
        };

        watch(selectedHanke, (newVal, oldVal) => {
            if (newVal && newVal !== oldVal && oldVal !== '') {
                aiResponse.value = null;
                const selectedHankeDetails = hankes.value.find(hanke => hanke.id === newVal);
                console.log('Selected Hanke A:', selectedHankeDetails);
                if (selectedHankeDetails) {
                    let jsonobject = convertFirebaseToJson(selectedHankeDetails);
                    store.dispatch('content/updateAiResponse', jsonobject);
                }
            } else if (projectName.value == '') {
                const selectedHankeDetails = hankes.value.find(hanke => hanke.id === newVal);
                console.log('Selected Hanke B:', selectedHankeDetails);
                if (selectedHankeDetails) {
                    let jsonobject = convertFirebaseToJson(selectedHankeDetails);
                    store.dispatch('content/updateAiResponse', jsonobject);

                }
            }

            else {
                console.log("Ah, it's just the initial load. Carry on, then.");
            }


        });

        const fetchHankes = async () => {
            const currentUser = store.state.auth.user;
            if (!currentUser) {
                console.error("No user logged in.");
                return;
            }
            const hankeRef = firebaseRef(database, 'hankkeet');
            try {
                const snapshot = await get(hankeRef);
                if (snapshot.exists()) {
                    const allHankes = Object.entries(snapshot.val()).map(([id, hanke]) => ({
                        id,
                        name: hanke.name,
                        details: hanke.details,
                        sections: hanke.sections,
                        userId: hanke.userId
                    }));
                    hankes.value = allHankes.filter(hanke => hanke.userId === currentUser.uid);
                } else {
                    hankes.value = [];
                }
            } catch (error) {
                console.error("Error fetching Hankes:", error);
            }
        };

        onMounted(async () => {
            fetchTokenUsage();

            checkAuthentication();

            const user = store.state.auth.user;


            if (user) {
                const userRef = firebaseRef(database, `users/${user.uid}`);
                onValue(userRef, (snapshot) => {
                    const userData = snapshot.val();
                    if (userData && userData.credits) {
                        userCredits.value = userData.credits;
                    }
                });
            }

            await fetchHankes();

            if (projectId.value) {
                // Fetch project data immediately if projectId is already available
                console.log(`Fetching project data for ID: ${projectId.value}`);
                const user = store.state.auth.user;
                if (user) {
                    const projectRef = firebaseRef(database, `users/${user.uid}/projects/` + encodeURIComponent(projectId.value));
                    try {
                        const snapshot = await get(projectRef);
                        console.log('Snapshot:', snapshot);
                        if (snapshot.exists()) {
                            const projectData = snapshot.val();

                            if (projectData.scrapedTexts) {
                                scrapedTexts.value = projectData.scrapedTexts;
                            } else {
                                scrapedTexts.value = []; // No scraped texts? No problem. Here's an empty array.
                                console.log("No scraped texts found. Summoning an empty array from the void.");
                            }

                            console.log('Project data:', projectData);
                            selectedHanke.value = projectData.selectedHanke;
                            inputText.value = projectData.userInput;

                            store.dispatch('content/updateAiResponse', JSON.parse(projectData.aiResponse));
                            projectName.value = projectData.name;
                            store.dispatch('files/updateFiles', projectData.files || []);
                        } else {
                            console.error("No project data found for the given ID.");
                        }
                    } catch (error) {
                        console.error("Error fetching project data:", error);
                    }
                } else {
                    console.error("No user logged in.");
                }
            }

            window.addEventListener('beforeunload', () => {
                if (isRecording.value && mediaRecorder) {
                    mediaRecorder.value.stop();
                }
            });




        });






        return {
            files,
            formattedCritiqueResults,
            selectedHanke,
            inputText,
            aiResponse,
            showFeedbackModal,
            feedbackText,
            feedbackTarget,
            loadingAI,
            loadingEdits,
            isRecording,
            mediaRecorder,
            audioChunks,
            projectName,
            saveProject,

            toggleRecording,
            generatePDF,
            aiFixes,
            closeFeedbackModal,

            getChangedTexts,

            removeFile,
            parsePdf,
            extractTextFromPdf,


            handleLaunchAI,
            fillWithAI,
            sendAudioToCloud,
            hankes,
            websiteUrl,
            scrapedTexts,
            scrapeText,
            removeScrapedText,

            isLoadingText,
            isLoadingTextMic,
            saveAsJson,
            loadJsonClick,
            loadFromJson,
            jsonInput,
            handleFilePicked,
            driveFiles,
            handledriveFilesPicked,
            fillWithAIForAll,
            saveAsDocx,
            handleDefaultFeedback,
            aiResponseCardRef,
            predefinedTexts,
            applyPredefinedText,
            aiTemperature,
            handleCritique,
            critiqueResults,
            currentUser,
            handleLogout,
            tokensUsed,
            tokensRemaining,
            userCredits,
            fetchHankes,
            successMessage,
            toggleMenu,
            showMenu,
            inputTextArea,
            resizeTextarea,
            successMessageBottom


        };
    },


};


</script>

<style scoped>
.bg-white.overflow-hidden.shadow-xl.sm\:rounded-lg.p-6 {
    padding: 2rem;
    /* More padding, more power */
}

/* label font size */
.text-sm-label {
    font-size: 1.0rem;
}

.token-usage {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.slider {
    -webkit-appearance: none;
    width: 25%;
    height: 4px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}


@keyframes pulse {

    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }
}

.my-indigo-button {
    @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none mr-4;

}


.record-button {
    /* Styling for the button itself */
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    position: relative;
    /* For absolute positioning of the icon */
}

/* The non-recording state style */
.record-button:not(.is-recording) {
    background-color: #4caf50;
    /* A calming green */
}

/* The recording state style */
.record-button.is-recording {
    background-color: #ff4136;
    /* That fiery red again */
}

.record-button:hover {
    /* Let's make the hover state a bit darker for both scenarios */
    opacity: 0.9;
}

.record-icon {
    /* The base style for our icon */
    height: 20px;
    width: 20px;
    border-radius: 50%;
    /* Circle it up */
    transition: transform 0.3s;
}

/* The non-recording icon style */
.record-icon:not(.recording) {
    background-color: #fff;
    /* A nice, visible white */
}

/* The recording icon style */
.record-icon.recording {
    background-color: #fff;
    /* Keep it white to stand out */
    animation: pulse 1.5s infinite;
    /* Here come the jazz hands! */
}

/* The pulse animation for when we're recording */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

textarea#inputText {
    padding: 1rem;

}


.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>