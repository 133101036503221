<template>
    <div class="min-h-screen bg-gray-100 text-gray-900">
        <header class="px-4 lg:px-6 py-4 bg-white shadow">
            <div class="max-w-7xl mx-auto flex justify-between items-center">
                <a class="flex items-center" href="/" rel="ugc">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="text-indigo-600">
                        <path
                            d="M9.5 2A2.5 2.5 0 0 1 12 4.5v15a2.5 2.5 0 0 1-4.96.44 2.5 2.5 0 0 1-2.96-3.08 3 3 0 0 1-.34-5.58 2.5 2.5 0 0 1 1.32-4.24 2.5 2.5 0 0 1 1.98-3A2.5 2.5 0 0 1 9.5 2Z">
                        </path>
                        <path
                            d="M14.5 2A2.5 2.5 0 0 0 12 4.5v15a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 14.5 2Z">
                        </path>
                    </svg>
                    <span class="ml-2 text-2xl font-bold text-gray-800">Hankeapuri AI</span>
                </a>
                <nav class="flex items-center space-x-4">
                    <a class="text-sm font-medium hover:underline underline-offset-4" href="/hankeapuri"
                        rel="ugc">Hankeapuri</a>
                    <a class="text-sm font-medium hover:underline underline-offset-4" href="/hankkeet" rel="ugc">Omat
                        hankkeet</a>
                    <a class="text-sm font-medium hover:underline underline-offset-4" href="#"
                        @click.prevent="handleLogout" rel="ugc">Kirjaudu ulos</a>
                    <span v-if="currentUser" class="text-sm font-medium">Kirjautunut sisään käyttäjällä: {{
                        currentUser.email }}</span>
                </nav>
            </div>
        </header>
        <main class="container mx-auto px-4 py-8">
            <div class="bg-white rounded-lg shadow overflow-hidden">
                <NewKeyModal v-if="showModal" @confirm="handleConfirm" @cancel="handleCancel" />
                <NewSubKeyModal v-if="showSubKeyModal" @confirm="handleSubKeyConfirm" @cancel="handleSubKeyCancel" />
                <div class="p-6">
                    <div class="mb-8 flex items-center justify-between">
                        <select v-model="selectedHankeId" @change="loadSelectedHanke"
                            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md shadow-sm">
                            <option disabled value="">Valitse Luomasi hankepohja</option>
                            <option v-for="hanke in hankes" :key="hanke.id" :value="hanke.id"
                                class="text-gray-900 cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-100">
                                {{ hanke.name }}
                            </option>
                        </select>
                        <div class="ml-4 flex space-x-1">
                            <button @click="startFromScratch" class="green-button">
                                Uusi
                            </button>
                            <button @click="showTemplates = true" class="green-button">
                                Valmiit mallipohjat
                            </button>
                            <button @click="deleteSelectedHanke" class="red-button">
                                Poista
                            </button>
                        </div>
                    </div>
                    <templates v-if="showTemplates" @templateSelected="handleTemplateSelected"
                        @closeModal="showTemplates = false" />
                    <div v-if="selectedHankeId || jsonData || isLoading" class="mb-8">
                        <h3 class="text-xl font-semibold mb-2">Anna Hankepohjalle Nimi</h3>
                        <input type="text" v-model="name"
                            class="mb-4 block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
                        <h3 class="text-xl font-semibold mb-2">Anna tekoälylle lisätietoa hankkeen täytöstä</h3>
                        <textarea v-model="details" @input="resizeTextareaDetails" ref="detailsTextarea"
                            class="autoresize mt-1 block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            rows="1"></textarea>
                        <div v-if="selectedHankeId || jsonData" class="flex flex-wrap justify-start mt-8 mb-8">
                            <button @click="addMainKey"
                                class="text-blue-600 hover:text-blue-700 focus:outline-none mr-4">
                                <font-awesome-icon :icon="['fas', 'plus']" class="mr-1"></font-awesome-icon>
                                Lisää pääotsikko
                            </button>
                        </div>
                    </div>
                    <div v-if="jsonData || isLoading" class="mb-8">
                        <div v-for="section in jsonData.sections" :key="section.id"
                            class="mb-8 bg-white rounded-lg shadow-md">
                            <div class="flex justify-between items-center bg-gray-100 px-4 py-2 rounded-t-lg">
                                <h3 class="text-xl font-semibold">{{ section.text }}</h3>
                                <div class="flex items-center space-x-3">
                                    <button @click.prevent="removeMainKey(section.id)"
                                        class="px-3 py-1 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 focus:outline-none"
                                        title="Remove Title">
                                        <font-awesome-icon :icon="['fas', 'trash-alt']"
                                            class="mr-1"></font-awesome-icon>

                                    </button>
                                    <font-awesome-icon @click.prevent="moveKey(section.id, 'up')"
                                        :icon="['fas', 'arrow-up']"
                                        class="text-blue-600 hover:text-blue-700 cursor-pointer text-lg"
                                        title="Move Up"></font-awesome-icon>
                                    <font-awesome-icon @click.prevent="moveKey(section.id, 'down')"
                                        :icon="['fas', 'arrow-down']"
                                        class="text-blue-600 hover:text-blue-700 cursor-pointer text-lg"
                                        title="Move Down"></font-awesome-icon>
                                    <button @click="toggleCollapse(section.id)"
                                        class="px-3 py-1 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-300 focus:outline-none">
                                        {{ isCollapsed(section.id) ? '▼' : '▲' }}
                                    </button>
                                </div>
                            </div>
                            <div v-show="!isCollapsed(section.id)" class="p-4">
                                <div class="mb-4 flex items-center space-x-4">

                                    <button @click.prevent="addSubKey(section.id)" class="green-button"
                                        title="Add Subtitle">
                                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1"></font-awesome-icon>
                                        Lisää väliotsikko
                                    </button>
                                </div>
                                <div v-for="subSection in section.subSections" :key="subSection.id" class="mb-6">
                                    <label :for="subSection.id" class="block text-lg font-medium text-gray-700 mb-1">{{
                                        subSection.text
                                    }}<button @click.prevent="removeKey(section, subSection.id)"
                                            class="ml-2 text-red-600 hover:text-red-700 focus:outline-none"
                                            title="Remove">
                                            <font-awesome-icon :icon="['fas', 'trash-alt']"
                                                class="text-lg"></font-awesome-icon>
                                        </button>
                                        <button @click.prevent="editKeyName(section, subSection.id)"
                                            class="ml-2 text-yellow-600 hover:text-yellow-700 focus:outline-none"
                                            title="Edit Key Name">
                                            <font-awesome-icon :icon="['fas', 'edit']"
                                                class="text-lg"></font-awesome-icon>
                                        </button></label>
                                    <textarea :id="subSection.id" v-model="subSection.description"
                                        class="autoresize mt-1 block w-full px-3 py-2 text-gray-700 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                        @input="resizeTextarea($event)" :ref="el => textareaRefs[subSection.id] = el"
                                        rows="1"></textarea>
                                    <div class="mt-2 flex items-center space-x-2">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedHankeId || jsonData" class="flex flex-wrap justify-start">

                        <button @click="saveHankeToFirebase" class="green-button">
                            Tallenna hankepohja
                        </button>

                    </div>
                    <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none"
                        accept=".json" />
                </div>
            </div>
            <div>
                <div v-if="selectedHankeId || jsonData || isLoading"
                    class="mt-8 bg-white rounded-lg shadow overflow-hidden">
                    <div class="px-6 py-8">
                        <div class="mb-8">
                            <h2 class="text-2xl font-bold mb-4">Käytä tekoälyä hakemuspohjan luomiseen</h2>
                            <div class="drag-drop-area border-dashed border-4 border-gray-200 p-6 rounded-md">
                                <input type="file" id="fileUpload" ref="fileUpload" @change="handleFileChange" hidden
                                    accept=".pdf, .docx, .txt" multiple />
                                <div class="text-center" @dragover.prevent="dragOver" @drop.prevent="dropFile"
                                    @click="triggerFileUpload">
                                    <p class="text-gray-700">Lisää hakemuspohja (PDF,DOCX,TXT)</p>
                                    <p class="text-sm text-gray-500"></p>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <label for="customInput" class="block text-sm font-medium text-gray-700">Erityisohjeita
                                tekoälylle
                                hakemuspohjan luontiin</label>
                            <input type="text" id="customInput" v-model="customInput"
                                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                        <div class="file-list">
                            <div v-for="(file, index) in files" :key="index"
                                class="file-item flex justify-between items-center p-4 border-b border-gray-300 hover:bg-gray-100 transition-colors duration-300">
                                {{ file.name }}
                                <button @click="removeFile(index)" class="red-button">
                                    Poista
                                </button>
                            </div>
                        </div>
                        <div class="mt-8 flex space-x-4">
                            <button @click="launchAI" class="green-button">
                                {{ isLoading ? 'Luodaan...' : 'Käynnistä AI' }}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import { ref, reactive, onMounted, watch } from 'vue';
import templates from './templatesPage.vue';
import { nextTick } from 'vue';
import mammoth from 'mammoth';
import axios from 'axios';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import useAuthenticationCheck from '@/composables/useAuthenticationCheck';
GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';
const isLoading = ref(false);
const customInput = ref('');
import NewKeyModal from './NewKeyModal.vue';
import NewSubKeyModal from './NewSubKeyModal.vue';
import { database } from "@/firebase.js";
import { ref as firebaseRef, set, get, push } from "firebase/database";
const store = useStore();
const currentUser = ref(null);

const files = ref([]);
const name = ref('');
const details = ref('');
const hankes = ref([]);
const selectedHankeId = ref('');
const textareaRefs = ref({});
const jsonData = ref(null);
const collapsedKeys = reactive({})
const showModal = ref(false);
const showTemplates = ref(false);
const detailsTextarea = ref(null);

const fileUpload = ref(null);
const { checkAuthentication } = useAuthenticationCheck();
// Function to trigger the hidden file input
const triggerFileUpload = () => {
    fileUpload.value.click();
};

// Handle the file drop
const dropFile = (event) => {
    const files = event.dataTransfer.files;
    processFiles(files);
};

// Handle file selection via input
const handleFileChange = (event) => {
    const files = event.target.files;
    processFiles(files);
};

// Prevent default behavior to allow drop
const dragOver = (event) => {
    event.preventDefault();
};

const handleLogout = async () => {
    await store.dispatch('auth/logout');

};

// Placeholder for processing files
const processFiles = (filesToAdd) => {
    console.log("Processing files:", filesToAdd);
    console.log("Current files:", files.value);
    Array.from(filesToAdd).forEach(file => {
        // Check if the file is not already in the list to avoid duplicates
        if (!files.value.find(f => f.name === file.name)) {
            files.value.push(file);
        }
    });
};

const sanitizeKey = (key) => {
    return key.replace(/[.$#/[\]]/g, '_');
};

const handleTemplateSelected = (template) => {
    jsonData.value = template;
    name.value = template.name;
    details.value = template.details;
    showTemplates.value = false;

    //nextTick
    nextTick(() => {
        resizeTextareaDetails();
    });
};

/*
const launchAI = async () => {
    const formData = new FormData();
    jsonData.value = { name: '', details: '', sections: [] }; // Initialize with an empty object
    isLoading.value = true;

    // Process each file and append its content to formData
    for (const file of files.value) {
        let textContent = '';

        try {
            // Determine the file type and parse accordingly
            if (file.type === 'application/pdf') {
                textContent = await parsePdf(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                textContent = await parseDocx(file);
            }
            else if (file.type === 'text/plain') {
                // Handle basic TXT files
                textContent = await file.text();
            }
            else {
                console.warn(`Unsupported file type: ${file.type}`);
                continue; // Skip unsupported file types
            }

            // Append the extracted text content to formData
            formData.append(file.name + '_text', textContent);
        } catch (error) {
            console.error(`Error processing file ${file.name}:`, error);
        }
    }

    // Append other form data as needed
    // formData.append('otherData', 'value');
    formData.append('customInput', customInput.value);

    try {
        const response = await axios.post('https://www.jhappssandbox.com/gptserver/chat/hankeapuriProjectCreator.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        // Handle response
        if (response.data) {
            console.log("AI-generated project:", response.data);
            //json parse data
            response.data = JSON.parse(response.data);
            console.log("AI-generated project:", response.data);


            isLoading.value = false;
            jsonData.value = {
                name: '',
                details: '',
                sections: Object.entries(response.data).map(([sectionKey, sectionValue], index) => {
                    const sanitizedSectionKey = sanitizeKey(sectionKey); // Sanitize the section key
                    return {
                        id: sanitizedSectionKey,
                        text: sectionKey,
                        order: index,
                        subSections: Object.entries(sectionValue).map(([subKey, subValue], subIndex) => {
                            const sanitizedSubKey = sanitizeKey(subKey); // Sanitize the subsection key
                            return {
                                id: `${sanitizedSectionKey}_${sanitizedSubKey}`,
                                text: subKey,
                                description: subValue,
                                order: subIndex + 1,
                            };
                        }),
                    };
                }),
            };
            console.log("AI-generated project:", jsonData.value);

            initializeCollapsedKeys();


            nextTick(() => {
                // Resize all the textareas
                Object.values(textareaRefs.value).forEach(textarea => {
                    resizeTextarea({ target: textarea });
                });

                console.log("AI-generated project loaded successfully.");
            });


            console.log("AI-generated project loaded successfully.");
        } else {
            console.error("Failed to load AI-generated project: No data received.");

            Swal.fire({
                icon: 'warning',
                title: 'Huomio',
                text: 'Jotain meni pieleen taustalla, yritä uudestaan.',
            });
        }
    } catch (error) {
        console.error('Error launching AI:', error);
        Swal.fire({
            icon: 'warning',
            title: 'Huomio',
            text: 'Jotain meni pieleen taustalla, yritä uudestaan.' + error,
        });
    }
    isLoading.value = false;
};
*/

const launchAI = async () => {
    isLoading.value = true;

    // Initialize userIdea with form fields
    let userIdea = `
        Hankepohjan Nimi: ${name.value}
        Hankkeen Lisätiedot: ${details.value}
        Käyttäjän Antama Lisätieto: ${customInput.value}
    `;

    // Process each file and append its content to userIdea
    for (const file of files.value) {
        let textContent = '';

        try {
            // Determine the file type and parse accordingly
            if (file.type === 'application/pdf') {
                textContent = await parsePdf(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                textContent = await parseDocx(file);
            }
            else if (file.type === 'text/plain') {
                // Handle basic TXT files
                textContent = await file.text();
            }
            else {
                console.warn(`Unsupported file type: ${file.type}`);
                continue; // Skip unsupported file types
            }

            // Append the extracted text content to userIdea
            userIdea += `\n\nTiedosto (${file.name}) Sisältö:\n${textContent}`;
        } catch (error) {
            console.error(`Error processing file ${file.name}:`, error);
        }
    }

    try {
        // Send the userIdea to the Azure function
        const response = await axios.post('https://jhapps-flutterapps.azurewebsites.net/api/hankeapuriCreator', {
            userIdea: userIdea.trim(),
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log(response.data);

        // Handle response
        if (response.data) {
            console.log("AI-generated project:", response.data.content);

            // Assign the AI-generated JSON structure to jsonData.value after sanitizing keys
            jsonData.value = {
                name: '',
                details: '',
                sections: Object.entries(response.data).map(([sectionKey, sectionValue], index) => {
                    const sanitizedSectionKey = sanitizeKey(sectionKey); // Sanitize the section key
                    return {
                        id: sanitizedSectionKey,
                        text: sectionKey,
                        order: index,
                        subSections: Object.entries(sectionValue).map(([subKey, subValue], subIndex) => {
                            const sanitizedSubKey = sanitizeKey(subKey); // Sanitize the subsection key
                            return {
                                id: `${sanitizedSectionKey}_${sanitizedSubKey}`,
                                text: subKey,
                                description: subValue,
                                order: subIndex + 1,
                            };
                        }),
                    };
                }),
            };
            console.log("AI-generated project (sanitized):", jsonData.value);

            // Initialize collapse states for sections
            initializeCollapsedKeys();

            nextTick(() => {
                // Resize all the textareas
                Object.values(textareaRefs.value).forEach(textarea => {
                    resizeTextarea({ target: textarea });
                });

                console.log("AI-generated JSON structure loaded successfully.");
            });

            Swal.fire({
                icon: 'success',
                title: 'Onnistui',
                text: 'Hankepohjan JSON-rakenne luotiin onnistuneesti.',
            });
        } else {
            console.error("Failed to load AI-generated JSON structure: No data received.");
            Swal.fire({
                icon: 'warning',
                title: 'Huomio',
                text: 'Jotain meni pieleen taustalla, yritä uudestaan.',
            });
        }
    } catch (error) {
        console.error('Error launching AI:', error);
        Swal.fire({
            icon: 'warning',
            title: 'Huomio',
            text: 'Jotain meni pieleen taustalla, yritä uudestaan. ' + error.message,
        });
    } finally {
        isLoading.value = false;
    }
};



onMounted(async () => {

    checkAuthentication();
    currentUser.value = store.state.auth.user; // Get the current user from the Vuex store
    customInput.value = 'Lisää hankkeen perustiedot ja projektiin liittyvät kysymykset lisätietoineen. ';

    try {
        await fetchHankes();
        Object.values(textareaRefs.value).forEach(textarea => {
            resizeTextarea({ target: textarea });
        });
    } catch (error) {
        console.error("Error fetching Hankes:", error);
    }
});
const resizeTextarea = (event) => {
    const textarea = event.target;
    // Ensure the textarea is visible
    if (textarea && textarea.offsetParent !== null) {
        textarea.style.height = 'auto'; // Reset height to recalculate
        const newHeight = `${textarea.scrollHeight}px`;
        textarea.style.height = newHeight;
        console.log("Resizing textarea to:", newHeight);
    } else {
        console.log("Textarea is not visible. Skipping resize.");
    }
};

const resizeTextareaDetails = () => {
    const textarea = detailsTextarea.value;
    if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }
};



const fileInput = ref(null);



const deleteSelectedHanke = async () => {
    if (!selectedHankeId.value) {
        Swal.fire({
            icon: 'warning',
            title: 'Varoitus',
            text: 'Valitse ensin hanke, jonka haluat poistaa.',
        });
        return;
    }

    const result = await Swal.fire({
        title: 'Oletko varma?',
        text: 'Haluatko varmasti poistaa hankkeen? Tätä toimintoa ei voi peruuttaa.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Kyllä, poista',
        cancelButtonText: 'Peruuta',
    });

    if (result.isConfirmed) {
        const hankeRef = firebaseRef(database, `hankkeet/${selectedHankeId.value}`);
        try {
            await set(hankeRef, null); // Poof! It's gone!
            // Refresh the list after successful deletion
            await fetchHankes();
            selectedHankeId.value = ''; // Clear the selected hanke
        } catch (error) {
            console.error("Error deleting Hanke:", error);
        }
    }
};


const fetchHankes = async () => {
    const currentUser = store.state.auth.user;
    if (!currentUser) {
        console.error("No user logged in.");
        return;
    }
    const hankeRef = firebaseRef(database, 'hankkeet');
    try {
        const snapshot = await get(hankeRef);
        if (snapshot.exists()) {
            const allHankes = Object.entries(snapshot.val())
                .map(([id, hanke]) => ({ id, ...hanke }))
                .filter(hanke => hanke.userId === currentUser.uid);
            hankes.value = allHankes;
        } else {
            hankes.value = [];
        }
    } catch (error) {
        console.error("Error fetching Hankes:", error);
    }
};



const initializeCollapsedKeys = () => {
    jsonData.value.sections.forEach((section) => {
        if (collapsedKeys[section.id] === undefined) {
            collapsedKeys[section.id] = true;
        }
    });
};


const parsePdf = async (file) => {
    try {
        const pdf = await getDocument(URL.createObjectURL(file)).promise;
        let textContent = '';
        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const text = await page.getTextContent();
            textContent += text.items.map(item => item.str).join(' ');
        }
        return textContent;
    } catch (error) {
        console.error("Error parsing PDF:", error);
        return '';
    }
};

const parseDocx = async (file) => {
    try {
        const arrayBuffer = await file.arrayBuffer();
        const result = await mammoth.extractRawText({ arrayBuffer });
        return result.value; // The raw text
    } catch (error) {
        console.error("Error parsing DOCX:", error);
        return '';
    }
};



const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/json") {
        try {
            const text = await file.text();
            const data = JSON.parse(text);
            jsonData.value = data; // Assuming jsonData is your reactive property holding the JSON data
            name.value = data.name || ''; // Update the hankename if it's in the file
            details.value = data.details || ''; // Update the hankedetails if it's in the file
        } catch (error) {
            console.error("Oops! Something went wrong while reading the file:", error);

            Swal.fire({
                icon: 'warning',
                title: 'Huomio',
                text: 'Tiedosto mahdollisesti väärässä muodossa. Yritä uudelleen.',
            });
        }
    } else {
        Swal.fire({
            icon: 'warning',
            title: 'Huomio',
            text: 'Tiedosto mahdollisesti väärässä muodossa. Yritä uudelleen.',
        });
    }
};
const moveKey = (sectionId, direction) => {
    const sections = jsonData.value.sections;
    const index = sections.findIndex(section => section.id === sectionId);
    if (index === -1) {
        console.warn("Trying to move a key that doesn't exist? That's a new one.");
        return;
    }
    let newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex >= sections.length) {
        console.warn("Easy there, cowboy! That key can't go any further.");
        return;
    }
    [sections[index], sections[newIndex]] = [sections[newIndex], sections[index]];
    sections.forEach((section, i) => {
        section.order = i + 1;
    });
};


const loadSelectedHanke = async () => {
    if (!selectedHankeId.value) return;
    const hankeRef = firebaseRef(database, `hankkeet/${selectedHankeId.value}`);
    try {
        const snapshot = await get(hankeRef);
        if (snapshot.exists()) {
            const hankeData = snapshot.val();
            name.value = hankeData.name || '';
            details.value = hankeData.details || '';
            jsonData.value = {
                name: hankeData.name,
                details: hankeData.details,
                sections: Object.entries(hankeData.sections || {})
                    .map(([id, section]) => ({
                        id,
                        ...section,
                        subSections: Object.entries(section.subSections || {}).map(([subId, subSection]) => ({
                            id: subId,
                            ...subSection,
                        })),
                    }))
                    .sort((a, b) => a.order - b.order), // Sort sections based on the order property
            };

            console.log("Loaded selected Hanke:", jsonData.value);

            nextTick(() => {
                resizeTextareaDetails();
            });

            initializeCollapsedKeys();
        }
    } catch (error) {
        console.error("Error loading selected Hanke:", error);
    }
};

const removeMainKey = (sectionId) => {
    jsonData.value.sections = jsonData.value.sections.filter(section => section.id !== sectionId);
};

const removeFile = (index) => {
    files.value.splice(index, 1);
    fileUpload.value.value = ''; // Clear the file input value
};


const startFromScratch = () => {
    jsonData.value = {
        name: '',
        details: '',
        sections: [],
    };

    name.value = ''; // Clear the name input
    details.value = ''; // Clear the details textarea

    nextTick(() => {
        resizeTextareaDetails(); // Resize the details textarea
    });
};


const toggleCollapse = (sectionId) => {
    console.log(sectionId);
    console.log(collapsedKeys)
    if (collapsedKeys[sectionId] === undefined) {
        collapsedKeys[sectionId] = true;
    } else {
        collapsedKeys[sectionId] = !collapsedKeys[sectionId];
    }
    nextTick(() => {
        Object.values(textareaRefs.value).forEach(textarea => {
            resizeTextarea({ target: textarea });
        });
    });
};



const saveHankeToFirebase = async () => {
    console.log("Commencing the grand Hanke save...");
    if (!name.value) {
        Swal.fire({
            icon: 'error',
            title: 'Virhe',
            text: 'Hankepohjalla täytyy olla nimi. Anna hankepohjalle nimi ja yritä uudestaan.',
        });
        return;
    }

    const currentUser = store.state.auth.user;
    if (!currentUser) {
        console.error("No user logged in.");
        return;
    }

    console.log("Hanke data to save:", jsonData.value);

    const hankeData = {
        name: name.value,
        details: details.value,
        sections: jsonData.value.sections.reduce((acc, section, index) => {
            acc[section.id] = {
                text: section.text,
                order: index + 1, // Use the index as the order value
                subSections: section.subSections.reduce((subAcc, subSection) => {
                    subAcc[subSection.id] = {
                        text: subSection.text,
                        description: subSection.description,
                        order: subSection.order,
                    };
                    return subAcc;
                }, {}),
            };
            return acc;
        }, {}),
        userId: currentUser.uid,
    };

    try {
        let hankeRef;
        if (selectedHankeId.value) {
            // If a hanke is selected, update the existing hanke
            hankeRef = firebaseRef(database, `hankkeet/${selectedHankeId.value}`);
            await set(hankeRef, hankeData);
        } else {
            // If no hanke is selected, create a new hanke
            const hankkeetRef = firebaseRef(database, 'hankkeet');
            const snapshot = await get(hankkeetRef);
            const existingHanke = Object.values(snapshot.val() || {}).find(
                (hanke) => hanke.name === name.value && hanke.userId === currentUser.uid
            );

            if (existingHanke) {
                // If a hanke with the same name already exists for the current user, update it
                hankeRef = firebaseRef(database, `hankkeet/${existingHanke.id}`);
                await set(hankeRef, { ...existingHanke, ...hankeData });
            } else {
                // If no hanke with the same name exists for the current user, create a new one
                hankeRef = push(hankkeetRef);
                await set(hankeRef, hankeData);
            }
        }

        await fetchHankes();
        Swal.fire({
            icon: 'success',
            title: 'Onnistui',
            text: 'Hankepohjan tallennus onnistui.',
        });
    } catch (error) {
        console.error("The Hanke save hit a snag:", error);
        Swal.fire({
            icon: 'error',
            title: 'Virhe',
            text: 'Hankepohjan tallennus epäonnistui. Yritä uudestaan.',
        });
    }
};

const isCollapsed = (sectionId) => {
    return collapsedKeys[sectionId] !== undefined ? collapsedKeys[sectionId] : false;
};




const addMainKey = () => {
    showModal.value = true;
};

const handleConfirm = (newKey) => {
    if (newKey && !jsonData.value.sections.some(section => section.text === newKey.text)) {
        console.log(jsonData.value);
        console.log("Adding new key:", newKey);
        console.log("Current JSON data:", jsonData.value);
        jsonData.value.sections.push({
            id: newKey.id,
            text: newKey.text,
            order: jsonData.value.sections.length + 1,
            subSections: [],
        });
        showModal.value = false;
    } else if (newKey) {
        Swal.fire({
            icon: 'warning',
            title: 'Huomio',
            text: 'Tällä nimellä on jo olemassa oleva pääotsikko. Valitse toinen nimi.',
        });
    }
};



const handleCancel = () => {
    showModal.value = false; // The user changed their mind, let the butler go.
};

const showSubKeyModal = ref(false);
const currentMainKey = ref(''); // To keep track of which main key we're adding a subkey to

const handleSubKeyConfirm = (newSubKey) => {
    const currentSection = jsonData.value.sections.find(section => section.id === currentMainKey.value);
    if (currentSection) {
        const newSubSectionId = push(firebaseRef(database, 'hankkeet')).key;
        currentSection.subSections.push({
            id: newSubSectionId,
            description: newSubKey.text.description,
            text: newSubKey.text.text,
            order: currentSection.subSections.length + 1,
        });
        showSubKeyModal.value = false;
    }
};
const handleSubKeyCancel = () => {
    showSubKeyModal.value = false; // The user got cold feet, hide the modal
};




const addSubKey = (sectionId) => {
    currentMainKey.value = sectionId;
    showSubKeyModal.value = true;
};


const removeKey = (section, subSectionId) => {
    section.subSections = section.subSections.filter(subSection => subSection.id !== subSectionId);
};


const editKeyName = (section, subSectionId) => {
    const subSection = section.subSections.find(subSection => subSection.id === subSectionId);
    if (subSection) {
        const newKey = prompt("Enter the new key name:", subSection.text);
        if (newKey && newKey !== subSection.text && !section.subSections.some(subSection => subSection.text === newKey)) {
            subSection.text = newKey;
        }
    }
};


watch(selectedHankeId, loadSelectedHanke);
</script>
<style scoped>
/* Define custom button classes using Tailwind's utility classes */
.green-button {
    @apply text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2
}

.blue-button {
    @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2
}

.red-button {
    @apply text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2
}
</style>