<template>
  <div class="flex flex-col min-h-screen">
    <TheHeader />
    <main class="flex-1">
      <section class="w-full py-12 md:py-24 lg:py-32 xl:py-48">
        <div class="container mx-auto px-4 md:px-6">
          <div class="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px]">
            <div class="flex flex-col items-center justify-center">
              <img src="../assets/kuva.png" alt="AI illustration"
                class="mx-auto aspect-video overflow-hidden rounded-xl object-cover sm:w-full lg:order-last lg:aspect-square"
                width="550" height="550" />
            </div>
            <div class="flex flex-col items-center justify-center space-y-4">
              <div class="space-y-2 text-center">
                <h1 class="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none pb-2">
                  Hankeapuri AI - Älykäs apuri liiketoiminnan kehittämiseen
                </h1>
                <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">
                  Helpota hankerahoituksen hakemista, liiketoimintasuunnitelmien laatimista ja raporttien luomista
                  Hankeapuri AI:n avulla. Syötä perustiedot, anna tekoälyn hoitaa loput.
                </p>
                <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">
                  Hyödynnä älykkäät algoritmit tietojesi analysointiin ja luo vaikuttavia dokumentteja hetkessä. Muokkaa
                  tuloksia tarpeen mukaan yhdellä klikkauksella.
                </p>
              </div>
              <div class="flex flex-col gap-2 min-[400px]:flex-row justify-center">
                <a class="my-indigo-button" :href="isLoggedIn ? '/hankkeet' : '/register'" rel="ugc">
                  {{ isLoggedIn ? 'Siirry Palveluun' : 'Aloita nyt' }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 md:py-24">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-wrap items-center -mx-4 justify-center">
            <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0 text-center">
              <h2 class="section-title">Monipuolinen työkalu liiketoiminnan kehittämiseen</h2>
              <p class="text-gray-500 mb-6">
                Hankeapuri AI nopeuttaa ja tehostaa:
                <br>- Rahoitushakemusten luomista
                <br>- Liiketoimintasuunnitelmien laatimista
                <br>- Hankeraporttien ja -dokumenttien tuottamista
              </p>
              <a href="/register" class="my-indigo-button">Tutustu tarkemmin</a>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <div class="bg-blue-100 p-6 rounded-2xl shadow-lg">
                <img src="kuva1.png" alt="Monipuolisuus" class="mb-4 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 md:py-24">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-wrap items-center -mx-4 justify-center">
            <div class="w-full lg:w-1/2 px-4">
              <div class="p-6 rounded-2xl shadow-lg">
                <img src="kuva2.png" alt="Tekoälyn voimaa" class="mb-4 mx-auto" />
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0 text-center">
              <h2 class="section-title">Tekoälyn voimaa liiketoiminnan tueksi</h2>
              <p class="text-gray-500 mb-6">
                Hankeapuri AI hyödyntää edistynyttä tekoälyä analysoidakseen tietoja ja luodakseen räätälöityjä
                dokumentteja. Älykäs algoritmi optimoi sisällön vastaamaan tarpeisiisi ja maksimoi
                onnistumismahdollisuutesi.
              </p>
              <a href="/register" class="my-indigo-button">Aloita käyttö</a>
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 md:py-24">
        <div class="container mx-auto px-4 md:px-6">
          <div class="flex flex-col items-center justify-center space-y-4">
            <div class="space-y-2 text-center">
              <h2 class="section-title">Katso demo video</h2>
              <p class="max-w-[600px] text-gray-500 md:text-xl dark:text-gray-400 pb-2">
                Näe miten Hankeapuri AI toimii käytännössä ja miten se voi auttaa sinua hankerahoituksessa.
              </p>
            </div>
            <div class="w-full relative" style="height: 350px; width:600px;">
              <div style="padding-top: 56.25%;"></div>
              <iframe class="absolute top-0 left-0 w-full h-full rounded-xl shadow-lg"
                src="https://www.youtube.com/embed/N4sovTlsME4" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>

      <section class="w-full py-12 bg-gray-100 dark:bg-gray-800">
        <div class="container mx-auto px-4 md:px-6">
          <div class="w-full max-w-2xl mx-auto space-y-12">
            <div class="space-y-2 text-center">
              <h2 class="section-title text-white">Keskeiset Hyödyt</h2>
              <p
                class="max-w-[900px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                Hankeapuri AI tehostaa liiketoiminnan kehittämistä monipuolisilla työkaluilla ja älykkäällä
                avustuksella.
              </p>
            </div>
            <div class="grid gap-6 md:grid-cols-2 lg:grid-cols-3 justify-center">
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Ajansäästö</h3>
                  <p class="text-gray-500 text-center">
                    Automatisoidut hakemuspohjat ja dokumentit valmistuvat nopeasti ja vaivattomasti.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Intuitiivinen</h3>
                  <p class="text-gray-500 text-center">
                    Käyttäjäystävällinen alusta sopii niin aloittelijoille kuin ammattilaisille.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Tekoälyn tuki</h3>
                  <p class="text-gray-500 text-center">
                    Tekoäly tarjoaa reaaliaikaisia ratkaisuja ja ehdotuksia sisällön optimointiin.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Ajan hermolla</h3>
                  <p class="text-gray-500 text-center">
                    Pidä hankedokumentaatiosi aina ajan tasalla markkinoiden viimeisimpien vaatimusten mukaisesti.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Tehostaminen</h3>
                  <p class="text-gray-500 text-center">
                    Optimoi rahoitushakemuksen jokainen vaihe parantaen mahdollisuuksiasi menestyksekkääseen rahoituksen
                    saantiin.
                  </p>
                </div>
              </div>
              <div
                class="rounded-lg border bg-white text-gray-800 shadow-md p-6 flex flex-col items-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
                <div class="flex flex-col space-y-2">
                  <h3 class="text-lg text-center font-semibold">Omat Hanke Pohjat</h3>
                  <p class="text-gray-500 text-center">
                    Luo helposti omia hakemuspohjia, jotka auttavat missä tahansa hakemuksessa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="w-full py-12 md:py-24 lg:py-32">
        <div class="container mx-auto px-4 md:px-6 flex flex-col items-center justify-center">
          <div class="space-y-2 text-center">
            <h2 class="section-title">Valmis tehostamaan liiketoimintaasi?</h2>
            <p
              class="max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400 mb-2">
              Kokeile nyt ilmaiseksi Hankeapuri AI:ta ja näe, miten se voi mullistaa liiketoimintasi kehittämisen.
            </p>
          </div>
          <div class="flex flex-col gap-2 min-[400px]:flex-row justify-center mt-5">
            <a class="my-indigo-button" href="/register" rel="ugc">Kokeile nyt</a>
          </div>
        </div>
      </section>
    </main>
    <footer class="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
      <p class="text-xs text-gray-500 dark:text-gray-400"> © Hankeapuri AI - JH Apps (Kaikki oikeudet pidätetään). </p>
      <nav class="sm:ml-auto flex gap-4 sm:gap-6">
        <a class="text-xs hover:underline underline-offset-4" href="/ehdot" rel="ugc"> Käyttöehdot </a>
        <a class="text-xs hover:underline underline-offset-4" href="/yksityisyys" rel="ugc"> Yksityisyys </a>
      </nav>
    </footer>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'HankeapuriAI',
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.state.auth.isAuthenticated);
    return { isLoggedIn };
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>