<!-- File: src/views/CreditsPage.vue -->
<template>
  <div class="min-h-screen bg-gray-100 text-gray-900">
    <!-- Header -->
    <header class="px-4 lg:px-6 py-4 bg-white shadow">
      <div class="max-w-7xl mx-auto flex flex-wrap justify-between items-center">
        <div class="flex items-center flex-shrink-0 text-indigo-600 mr-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <!-- SVG paths remain the same -->
          </svg>
          <span class="ml-2 text-2xl font-bold text-gray-800">Hankeapuri AI</span>
        </div>
        <nav class="w-full flex-grow lg:flex lg:items-center lg:w-auto">
          <div class="text-sm lg:flex-grow">
            <a href="/osta" class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">Osta
              lisää</a>
            <p class="block mt-4 lg:inline-block lg:mt-0 text-gray-700 mr-4">
              Krediittejä: <span class="text-gray-900">{{ (userCredits / 100).toFixed(1) }}</span>
            </p>
            <a href="/hankkeet"
              class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">Omat hankkeet</a>
            <a href="/hankepohjat"
              class="block mt-4 lg:inline-block lg:mt-0 text-indigo-600 hover:text-indigo-700 mr-4">Hankepohjat</a>
            <span v-if="currentUser" class="block mt-4 lg:inline-block lg:mt-0 text-gray-700">Käyttäjä: {{
              currentUser.email }}</span>
          </div>
          <div>
            <a href="#" @click.prevent="handleLogout"
              class="inline-block text-sm px-4 py-2 leading-none border rounded text-indigo-600 border-indigo-600 hover:border-transparent hover:text-white hover:bg-indigo-700 mt-4 lg:mt-0">Kirjaudu
              ulos</a>
          </div>
        </nav>
      </div>
    </header>

    <!-- Main Content -->
    <main class="py-12">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="bg-white rounded-lg shadow-md p-6">
          <h2 class="text-3xl font-semibold mb-8 text-center">Lataa Krediittejä Hankeapuriin</h2>
          <div class="flex flex-col lg:flex-row justify-center space-y-8 lg:space-y-0 lg:space-x-8">
            <!-- Pro Package -->
            <div class="flex-1 bg-white rounded-lg shadow-lg p-8 text-center flex flex-col justify-between">
              <div>
                <div class="flex items-center justify-center mb-6">
                  <svg class="w-8 h-8 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 13l4 4L19 7"></path>
                  </svg>
                  <h2 class="text-3xl font-bold text-gray-800">{{ proPackage.name }}</h2>
                </div>
                <p class="text-gray-600 text-xl mb-4">{{ proPackage.description }}</p>
                <p class="text-gray-700 mb-6">
                  Tällä paketilla saat riittävästi krediittejä 20-100 hakemuksen luomiseen, riippuen niiden laajuudesta.
                  Krediitit ovat käytettävissä vuoden ajan ostopäivästä.
                </p>
              </div>
              <button @click="purchaseCredits(proPackage)" :disabled="loading"
                class="my-indigo-button flex items-center justify-center mx-auto mt-4">
                <svg v-if="!loading" class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
                  </path>
                </svg>
                <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                Osta {{ proPackage.price }}€
              </button>
            </div>

            <!-- Tiny Package -->
            <div class="flex-1 bg-white rounded-lg shadow-lg p-8 text-center flex flex-col justify-between">
              <div>
                <div class="flex items-center justify-center mb-6">
                  <svg class="w-8 h-8 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 13l4 4L19 7"></path>
                  </svg>
                  <h2 class="text-3xl font-bold text-gray-800">{{ tinyPackage.name }}</h2>
                </div>
                <p class="text-gray-600 text-xl mb-4">{{ tinyPackage.description }}</p>
                <p class="text-gray-700 mb-6">
                  Sopiva paketti muutaman suuremman tai useamman pienemmän hakemuksen luomiseen. Krediitit ovat
                  käytettävissä vuoden ajan ostopäivästä.
                </p>
              </div>
              <button @click="purchaseCredits(tinyPackage)" :disabled="loading"
                class="my-indigo-button flex items-center justify-center mx-auto mt-4">
                <svg v-if="!loading" class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
                  </path>
                </svg>
                <svg v-else class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                  fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                Osta {{ tinyPackage.price }}€
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { database } from "@/firebase.js";
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Swal from 'sweetalert2';
import { ref as firebaseRef, onValue } from "firebase/database";
import { useRouter } from 'vue-router';
import useAuthenticationCheck from '@/composables/useAuthenticationCheck';

export default {
  name: 'CreditsPage',
  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const { checkAuthentication } = useAuthenticationCheck();

    const proPackage = ref({
      name: 'Pro-paketti',
      description: '10 000 krediittiä',
      price: 129,
      credits: 1000000,
      priceId: 'price_1Q3HKAIsMbyslr4whs2YRTW6',
    });

    const tinyPackage = ref({
      name: 'Pikku Paketti',
      description: '1000 krediittiä',
      price: 29,
      credits: 100000,
      priceId: 'price_1Q3HK7IsMbyslr4wiNX6uuvt',
    });

    const userCredits = ref(0);
    const currentUser = computed(() => store.state.auth.user);

    const fetchUserCredits = () => {
      console.log('fetchUserCredits');
      const user = store.state.auth.user;
      console.log('user', user);
      if (user) {
        const userRef = firebaseRef(database, `users/${user.uid}`);
        onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          console.log('userData', userData);
          if (userData && userData.credits) {
            console.log('userData.credits', userData.credits);
            userCredits.value = userData.credits;
          }
        });
      }
    };

    const stripePromise = loadStripe("pk_live_51Q2o39IsMbyslr4wrupcANC1q3b5O0i1Ej76AieghWWafSnc4deL3oKXVMoYyIYOGB4WcNdj9nBtc7A4wil29fjn00nISpgINw");

    const purchaseCredits = async (packageData) => {
      try {
        loading.value = true;
        const functions = getFunctions();
        const createStripeCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');

        const result = await createStripeCheckoutSession({
          priceId: packageData.priceId,
          credits: packageData.credits,
        });

        const { sessionId } = result.data;

        const stripe = await stripePromise;
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({ sessionId });
          if (error) {
            console.error('Stripe redirectToCheckout error:', error);
            throw new Error(error.message);
          }
        }
      } catch (error) {
        console.error('Error purchasing credits:', error);
        Swal.fire({
          icon: 'error',
          title: 'Virhe',
          text: 'Tapahtui virhe krediittien ostossa. Ole hyvä ja yritä uudelleen.',
        });
      } finally {
        loading.value = false;
      }
    };

    const handleLogout = async () => {
      try {
        await store.dispatch('auth/logout');
        router.push({ name: 'Login' });
      } catch (error) {
        console.error('Error logging out:', error);
        Swal.fire({
          icon: 'error',
          title: 'Virhe',
          text: 'Kirjautuminen ulos epäonnistui. Yritä uudelleen.',
        });
      }
    };

    onMounted(async () => {
      checkAuthentication();


      try {

        fetchUserCredits();
      } catch (error) {
        console.error('Error initializing auth:', error);
      }
    });

    return {
      proPackage,
      tinyPackage,
      userCredits,
      currentUser,
      purchaseCredits,
      handleLogout,
      loading,
    };
  },
};
</script>

<style scoped>
.my-indigo-button {
  background-color: #4f46e5;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.my-indigo-button:hover {
  background-color: #4338ca;
}

.my-indigo-button:disabled {
  background-color: #a5b4fc;
  cursor: not-allowed;
}
</style>