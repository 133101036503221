<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-100">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-20 w-20 text-green-500 mb-4" fill="none" viewBox="0 0 24 24"
      stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
    </svg>
    <h1 class="text-3xl font-semibold text-gray-800 mb-2">Maksu onnistui!</h1>
    <p class="text-gray-600 mb-6">
      Kiitos ostoksestasi. Maksutapahtuma on suoritettu onnistuneesti.
    </p>

    <router-link to="/hankeapuri" class="my-indigo-button">
      Siirry työpöydälle
    </router-link>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default {
  name: 'SuccessPage',
  setup() {
    const route = useRoute();
    const session = ref(null);

    onMounted(async () => {
      const sessionId = route.query.session_id;
      if (sessionId) {
        try {
          // Replace with your actual endpoint to retrieve session details
          const response = await axios.get(`/api/checkout-session?session_id=${sessionId}`);
          session.value = response.data;
        } catch (error) {
          console.error('Error fetching session details:', error);
        }
      }
    });

    return { session };
  },
};
</script>

<style scoped>
.my-indigo-button {
  background-color: #4f46e5;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.my-indigo-button:hover {
  background-color: #4338ca;
}
</style>